var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('div', {
          staticClass: "form"
        }, [_c('div', {
          staticClass: "card card-custom"
        }, [_c('div', {
          staticClass: "card-header border-0 pt-6 pb-0"
        }, [_c('div', {
          staticClass: "card-title align-items-start flex-column"
        }, [_c('h3', {
          staticClass: "card-label font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t("Nation Secondment Settings")) + " ")]), _c('span', {
          staticClass: "text-muted font-weight-bold font-size-sm mt-1"
        }, [_vm._v(" " + _vm._s(_vm.$t("Change Nation's secondment settings")) + " ")])]), _c('div', {
          staticClass: "card-toolbar"
        }, [_c('button', {
          staticClass: "btn btn-success mr-2",
          class: {
            'spinner spinner-light spinner-right': _vm.isSaving
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return handleSubmit(_vm.save);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Save Changes")) + " ")])])]), _c('div', {
          staticClass: "card-body detail"
        }, [_c('h5', {
          staticClass: "mb-10 font-weight-bold text-dark"
        }, [_vm._v(_vm._s(_vm.$t("Secondment Service")))]), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Service'),
            "vid": "service",
            "rules": "required",
            "tag": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('euro-radio', {
                attrs: {
                  "options": _vm.OPTIONS,
                  "label": "".concat(_vm.$t('Service available'), "*"),
                  "horizontal": "",
                  "error-messages": errors,
                  "size": "lg"
                },
                model: {
                  value: _vm.form.secondments.service_available,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.secondments, "service_available", $$v);
                  },
                  expression: "form.secondments.service_available"
                }
              })];
            }
          }], null, true)
        }), _c('div', {
          staticClass: "mb-10 pt-5"
        }, [_c('h5', {
          staticClass: "font-weight-bold text-dark"
        }, [_vm._v(_vm._s(_vm.$t("Secondment Service Fields")))]), !_vm.form.secondments.service_available ? _c('span', {
          staticClass: "mb-6 text-muted font-weight-bold font-size-sm mt-1"
        }, [_vm._v(" " + _vm._s(_vm.$t("To change the following fields activate service for the country")) + ". ")]) : _vm._e()]), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Elaboration time'),
            "vid": "elaboration_time",
            "rules": "max:30|required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": "".concat(_vm.$t('Elaboration time')),
                  "disabled": !_vm.form.secondments.service_available,
                  "horizontal": "",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.secondments.elaboration_time,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.secondments, "elaboration_time", $$v);
                  },
                  expression: "form.secondments.elaboration_time"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Allow add'),
            "vid": "add_employee",
            "rules": "required",
            "tag": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('euro-radio', {
                attrs: {
                  "disabled": !_vm.form.secondments.service_available,
                  "options": _vm.OPTIONS,
                  "label": "".concat(_vm.$t('Allow adding employee'), "*"),
                  "horizontal": "",
                  "error-messages": errors,
                  "size": "lg"
                },
                model: {
                  value: _vm.form.secondments.allow_adding_employee,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.secondments, "allow_adding_employee", $$v);
                  },
                  expression: "form.secondments.allow_adding_employee"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Allow extension'),
            "vid": "allow_extension",
            "rules": "required",
            "tag": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('euro-radio', {
                attrs: {
                  "disabled": !_vm.form.secondments.service_available,
                  "options": _vm.OPTIONS,
                  "label": "".concat(_vm.$t('Allow secondment extension'), "*"),
                  "horizontal": "",
                  "error-messages": errors,
                  "size": "lg"
                },
                model: {
                  value: _vm.form.secondments.allow_extension,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.secondments, "allow_extension", $$v);
                  },
                  expression: "form.secondments.allow_extension"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Allow return'),
            "vid": "allow_return",
            "rules": "required",
            "tag": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('euro-radio', {
                attrs: {
                  "disabled": !_vm.form.secondments.service_available,
                  "options": _vm.OPTIONS,
                  "label": "".concat(_vm.$t('Allow employees premature return'), "*"),
                  "horizontal": "",
                  "error-messages": errors,
                  "size": "lg"
                },
                model: {
                  value: _vm.form.secondments.allow_premature_return,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.secondments, "allow_premature_return", $$v);
                  },
                  expression: "form.secondments.allow_premature_return"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": _vm.$t('Allow cancellation'),
            "vid": "allow_cancellation",
            "rules": "required",
            "tag": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('euro-radio', {
                attrs: {
                  "disabled": !_vm.form.secondments.service_available,
                  "options": _vm.OPTIONS,
                  "label": "".concat(_vm.$t('Allow secondment cancellation'), "*"),
                  "horizontal": "",
                  "error-messages": errors,
                  "size": "lg"
                },
                model: {
                  value: _vm.form.secondments.allow_cancellation,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.secondments, "allow_cancellation", $$v);
                  },
                  expression: "form.secondments.allow_cancellation"
                }
              })];
            }
          }], null, true)
        })], 1)])])];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }